import React from 'react'

const About = () => {
  return (
     <section id='about-us' className='overflow-x-hidden'>
       <div className="container">
            <h2 className='text-2xl text-smallTextColor text-center underline-offset-4 '>ABOUT US</h2>
            <div className="flex flex-col justify-center items-center">
              
              <div className="w-40 bg-primary h-1 mt-1"></div>
             </div>
        <div  data-aos="fade-right" className="container flex flex-col  mx-auto mt-5 space-y-6 md:space-y-0  md:flex-row mb-6">
          <div className="flex flex-col">
           </div>
          <div className="p-12 shadow-sm w-full bg-gray-50 rounded-sm flex flex-col justify-center items-center">
          <p> 
          We believe in unlocking the locked.
          We are a versatile technology company with a laser focus on delivering exceptional solutions across various domains.  From responsive websites that leave a lasting impression to intuitive mobile apps that elevate user experiences, and visually stunning graphics that communicate your brand story, we are committed to transforming your digital aspirations into reality.
          </p>
          
        
          <div className='py-4'>
          <a className="bg-smallTextColor py-2 px-4 rounded-lg  hover:bg-primary-light text-gray-200 " href="about-us">Learn more</a>
    </div>
    </div>
    </div>
       </div>
     </section>
  )
}

export default About