import React from 'react'
import logo from '../../assets/Ollata-logo-transparent-vertical.png';
import {BsTelegram,BsLinkedin} from 'react-icons/bs';

const Footer = () => {
    const year = new Date().getFullYear();

  return (
    <footer className='bg-primaryColor pt-12 bg'>
        <div className="container ">
            <div className="sm:flex items-center justify-between gap-4 md:gap-8">
                 <div className="w-full sm:1/2">
                 <div className=' flex items-center'>
                  <img src={logo} alt='logo' className=''/>
                  {/* <div className='flex flex-col  ml-3 '>
                  <h2 className=' text-lg md:text-xl text-headingColor font-[700]'><span className='bg-gradient-to-r from-smallTextColor flex-col  to-indigo-400 inline-block text-transparent bg-clip-text'>OLLATA</span> </h2>
                   <p><span className='text-[10px] bg-gradient-to-r from-smallTextColor via-green-500 to-indigo-400  text-transparent bg-clip-text'>SOFTWARE SOLUTIONS</span> </p>
                  </div> */}
             </div>
                 </div>
                 <div className="w-full sm:1/2">
                    <h2 className='text-[26px] text-white font-[600] mb-5 md:text-[1rem]'>Company</h2>

                    <ul className='flex items-start flex-col gap-4 '>
                   
                    <li><a href='#about' className='hover:text-headingColor cursor-pointer text-smallTextColor font-[600] smooth-transition duration-500'>ABOUT US</a></li>
                    <li><a href='#services' className='hover:text-headingColor cursor-pointer text-smallTextColor font-[600] smooth-transition duration-500'>SERVICES</a></li>
                    
                    <li><a href='#contact' className='hover:text-headingColor cursor-pointer text-smallTextColor font-[600] smooth-transition duration-500'>CONTACT</a></li>

                </ul>

                 </div>
                 <div className="w-full sm:1/2">
                 <span className='text-secondaryColor text-2xl font-[600] mt-4'>Follow Us</span>
                    <div className="flex  gap-4 flex-wrap md:gap-8 md:mt-10 mb-4 ">
                        
                    
                            <span >
                                <a href="https://t.me/ollatasoftware" className='text-secondaryColor hover:text-smallTextColor text-3xl font-[600]'>
                                <BsTelegram size={30} />
                                </a>
                            </span>
                            
                            <span >
                                <a href="https://www.linkedin.com/company/ollata-software/" className='text-secondaryColor hover:text-smallTextColor text-3xl font-[600]'>
                                <BsLinkedin size={30}/>
                                </a>
                            </span>
                           

                    </div>
                 </div>
            </div>
        </div>
        {/* footer bottom */}

        <div className=" bg-[#1b1e29] py-3 mt-14 bg">
             <div className="container">
                <div className="flex flex-col md:flex-row items-center justify-center sm:justify-between ">
                    
                    <p className='text-gray-400 text-[14px] pl-8'>Copyright {year} Ollata- All rights reserved.</p>
                    <p className='text-gray-400 text-[14px] pl-8'>Designed by <span>Ollata Software Solutions</span></p>
                </div>
             </div>
        </div>
    </footer>
  )
}

export default Footer