import React from 'react'

import website from '../../assets/website.jpg';
import mob from '../../assets/mob.jpg';
import graphics from '../../assets/graphics.jpg';
import laptopkeyboard from '../../assets/laptopkeyboard.jpg';
import {CgWebsite} from 'react-icons/cg';
import {AiOutlineAppstore} from 'react-icons/ai';
import {SiAltiumdesigner} from 'react-icons/si';

 const Service = () => {
  return (
    <section id='services' className='overflow-x-hidden bg-primaryColor'>
      <h2 className='text-2xl text-smallTextColor font-[500] text-center underline-offset-8'>WHAT WE DO?</h2>
      <h1 data-aos="fade-down" className='text-2xl text-secondaryColor  text-center font-bold pl-8 mb-12 transform-uppercase'>Open the door to limitless possibilities!</h1>
           <div className="">
           <div className=" container grid grid-cols-1 justify-center items-center gap-2  md:grid-cols-3 px-6 ">
        <div  className="w-80  bg-gray-300 rounded-md h-96  transorm transition-transform duration-500 hover:border-2 hover:border-smallTextColor  hover:scale-110 cursor-pointer">
        <div  className='h-1/3  bg-primaryColor p-10 '>    
                <h1 className="text-3xl text-gray-300">Website Development</h1>
                </div>
            <img src={website} alt="web developments"/>
        </div>
        <div  className="w-80  bg-gray-300 rounded-md h-96  transorm transition-transform duration-500 hover:border-2 hover:border-smallTextColor  hover:scale-110 cursor-pointer">
        <div className='h-1/3 bg-primaryColor p-10'>    
                <h1 className="text-2xl text-gray-300">Mobile  Application Development</h1>
                </div>
           
            <img  src={mob} alt="app developments" className='object-contain h-3/4'/>
        </div>
        <div  className="w-80  bg-gray-300 rounded-md h-96  transorm transition-transform duration-500 hover:border-2 hover:border-smallTextColor  hover:scale-110 cursor-pointer">
               <div className='h-1/3 bg-primaryColor p-10'>    
                <h1 className="text-3xl text-gray-300">Graphics Design </h1>
                </div>
            <img src={graphics} className='object-contain' alt="desktop app developments"/>
        </div>
     </div>
     <div className=' bg-gradient-to-tr from-purple-400 to-green-700  mt-10 w-full h-96 justify-center items-center'>
         <img src={laptopkeyboard} alt='it solution' className='absolute object-cover mix-blend-overlay h-96 w-full' />
         <h1 data-aos="zoom-out" data-aos-delay="100" className='text-gray-300 text-3xl font-bold text-center self-center items-center mt-10 pt-20'>Unlock the potential of your digital presence.</h1>
         <h3 data-aos="fade-right" data-aos-delay="200" className='text-center text-gray-300'>Amplifying your brand reach, engaging customers, and driving conversions like never before.</h3>
         
     </div>
     
           
           </div>
           {/*  */}
           
           <div className="container py-10">
           <h1 className='font-bold text-1xl uppercase text-smallTextColor mb-16'>Our Services</h1>
             <div className="flex flex-col md:flex-row md:space-x-6  md:space-y-0 space-y-12">
                   <div className="flex flex-col space-y-6">
                     <div className="flex space-x-6">
                       <div className="p-6 h-20 w-20 rounded-full bg-secondaryColor">
                         <CgWebsite  size={30}/>
                       </div>
                       <div data-aos="zoom-in">

                      <h1 className='text-gray-300 text-2xl font-bold'>Website Development</h1>
                    <p className='text-gray-400'>We bring your online presence to life, creating engaging and user-friendly websites that captivate your audience and drive results.</p>
                      </div>

                     </div>
                   </div>
                   <div className="flex flex-col space-y-6">
                     <div className="flex space-x-6">
                       <div className="p-6 h-20 w-20 rounded-full bg-secondaryColor">
                       <AiOutlineAppstore size={30} />
                       </div>
                       <div data-aos="zoom-in">

                      <h1 className='text-gray-300 text-2xl font-bold'>Mobile Application</h1>
                    <p className='text-gray-400'>We harness the power of the mobile world, crafting intuitive and feature-rich applications that keep you connected with your users on the go.</p>
                      </div>

                     </div>
                   </div>
                   <div className="flex flex-col space-y-6">
                     <div className="flex space-x-6">
                       <div className="p-6 h-20 w-20 rounded-full bg-secondaryColor">
                       <SiAltiumdesigner size={30} />
                       </div>
                       <div data-aos="zoom-in">

                      <h1 className='text-gray-300 text-2xl font-bold'>Graphics Design</h1>
                    <p className='text-gray-400'>Our talented graphic designers are adept at transforming your ideas into visually stunning creations. We ensure your brand stands out and leaves a lasting impression.</p>
                      </div>

                     </div>
                   </div>
             </div>
           </div>
        
           {/*  */}
    </section>
  )
}

 export default Service
