import React from 'react';

const Hero = () => {
 
  
  return (
    <section id='home' className='overflow-x-hidden h-screen bg overflow-hidden pt-40'>
     <div    className='flex flex-col items-center bg-cover justify-center bg'>
           <p className='font-extrabold  text-3xl md:text-5xl text-white mt-24 max-w-3xl text-center leading-relaxed'>We unleash the Potential of Technology for Your growth and success</p>
           <h2 className='text-2xl  font-bold text-smallTextColor text-center'>Cultivating <span className='text-white'>Growth</span>, Crafting <span  className='text-white'> Solutions</span>.</h2>
           <a href="#contact" className='bg-smallTextColor py-2 px-4 mt-20 shadow-lg text-secondaryColor font-[600] border-2 border-smallTextColor hover:bg-transparent rounded-md'>CONTACT US</a>
        </div>

        
    </section>
  )
}

export default Hero