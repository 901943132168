import React from 'react'
import {AiFillPhone,AiOutlineMail} from 'react-icons/ai';
import {MdLocationPin} from 'react-icons/md';


const Contact = () => {
  return (
    <section id='contact' className=' overflow-x-hidden '>
        <div className="">
            <h2 className='text-2xl text-smallTextColor font-[600] underline-offset-4 text-center'>GET IN TOUCH</h2>
            <div className="flex flex-col md:flex-row p-16 space-y-8 items-center">
            <div className='flex flex-col space-y-8 md:w-1/2'>
                <h1 className='text-3xl text-primaryColor font-bold mb-8'>Contacts</h1>
                 <div className='flex space-x-6 items-center' data-aos="fade-right">
                    <div className='p-2 rounded-full bg-smallTextColor w-16 h-16 flex justify-center items-center '>
                      <AiFillPhone size={30} className='text-secondaryColor' />
                    </div>
                    <a className='text-primaryColor' href='+251974890490'>+251-949835327</a>
                 </div>
                 <div className='flex space-x-6 items-center' data-aos="fade-right">
                    <div className='p-2 rounded-full bg-smallTextColor w-16 h-16 flex justify-center items-center '>
                        <AiOutlineMail  size={30} className='text-secondaryColor'/>
                    </div>
                    <a className='text-primaryColor' href='nagesohaile23@gmail.com'>info@ollatasoftware.com</a>
                 </div>
                 <div className='flex space-x-6 items-center' data-aos="fade-right">
                    <div className='p-2 rounded-full bg-smallTextColor w-16 h-16 flex justify-center items-center'>
                    <MdLocationPin size={30} className='text-secondaryColor' />
                    </div>
                  <address className='text-primaryColor'>Addis Ababa, Ethiopia</address>
                 </div>
            </div>
            {/* form */}

            <div className='md:w-1/2 flex flex-col w-full'>
                <h1 className='text-xl text-center text-primaryColor font-semibold'>Don't hasitate to contact us. Send us your feedback.</h1>
             <form class="text-gray-300 shadow-md rounded px-8 pt-6 pb-8 mb-4 ">
                <div class="mb-4">
                <label class="block text-gray-400 text-sm font-bold mb-2" for="username">
                    Name
                </label>
                <input class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="text" placeholder="your name" />
                </div>
                <div class="mb-4">
                <label class="block text-gray-400 text-sm font-bold mb-2" for="username">
                    Email
                </label>
                <input class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="email" type="text" placeholder="youremail@gmail.com" />
                </div>
                <div class="mb-4">
                <label class="block text-gray-400 text-sm font-bold mb-2" for="username">
                    Message
                </label>
                <textarea class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="email" type="text" placeholder="write your message here..." > </textarea>
                </div>
            
            
                <button class="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button">
                    Send
                </button>
                
    
            </form>
            </div>

            {/* form end */}

            </div>
        </div>
    </section>
  )
}

export default Contact