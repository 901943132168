import React,{useEffect,useState} from "react";
import Header from "./components/Header/Header"
import Footer from './components/Footer/Footer';
import Hero from "./components/UI/Hero";
import About from "./components/UI/About";
import Service from "./components/UI/Service";
// import Blog from "./components/UI/Blog";
import Contact from "./components/UI/Contact";
import AOS from 'aos';
import 'aos/dist/aos.css';
import CircleLoader from "react-spinners/CircleLoader";

import Helmet from 'react-helmet';
import Pricing from "./components/UI/Pricing";
import {BsTelegram} from 'react-icons/bs';

const CSSProperties = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
  
};

export default function App() {
  const [loading,setLoading] =useState(false);


  const [showButton, setShowButton] = useState(false);
  useEffect(()=>{
    setLoading(true);
    setTimeout(()=>{
         setLoading(false);
    },4000) 
    AOS.init();
    window.addEventListener("scroll", () => {
      if (document.body.scrollTop >200 || document.documentElement.scrollTop > 200) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });
    AOS.init()
  },[])


  


  return (
    <>

   {
    loading? <div className="flex bg-gray-800 items-center justify-center w-full h-screen">
      <CircleLoader
    color={"#4261C9"}
    loading={loading} 
    cssOverride={CSSProperties}
    size={150}
    aria-label="Loading Spinner"
    data-testid="loader"
  /> </div> :
    <div className="Application">
       {/*  ================Particles start =========== */}
               
       

       {/*============== Particles end==================== */}
  <Helmet>
  <meta charSet='utf-8' />
            <title>Ollata Software Solutions</title>
            <meta name='description' content='We believe in unlocking the locked. We are a versatile technology company with a laser focus on delivering exceptional solutions across various domains. We build web app, mobile apps for various institutions.' />
            <link rel='canonical' href='https://ollatasoftware.com/' />
   </Helmet>
   {showButton && (
        <a href="https://t.me/ollatasoftware" className="fixed rounded-full w-[3rem] h-[3rem] bottom-10 flex items-center justify-center right-5 z-50 bg-smallTextColor">
          <BsTelegram className="text-primaryColor" size={30}/>
        </a>
      )}
    <Header/>
    <main>
     <Hero />
     <About />
     <Service />
     {/* <Pricing /> */}
    {/* <Blog /> */}
    <Contact />
    </main>
    <Footer/>
  
   </div>
   }
   </>
  )
}