import React,{useRef,useState} from 'react'
import logo from '../../assets/Ollata-logo-transparent-horizontal.png';

import {AiOutlineMenu,AiOutlineClose} from 'react-icons/ai'

const Header = () => {
    // const menuRef = useRef(null);
    const [mobileMenuOpen,setmobileMenuOpen] = useState(false);
    
    
    const buttonRef = useRef(null);

   
    const toggleMenu = ()=>setmobileMenuOpen(!mobileMenuOpen);

   
  return <header  className='w-full h-[80px] bg flex items-center fixed top-0 z-10 bg-primaryColor'>
      
       <div className="container">
          <div className="flex items-center justify-between">
              {/* ===============Logo start============= */}
             <div className=' flex items-center'>
                  <img src={logo} alt='logo' className='h-[100px] w-[150px]'/>
                  {/* <div className='flex flex-col  ml-3 '>
                  <h2 className=' text-lg md:text-xl text-headingColor font-[700]'><span className='bg-gradient-to-r from-smallTextColor flex-col  to-indigo-400 inline-block text-transparent bg-clip-text'>OLLATA</span> </h2>
                   <p><span className='text-[10px] text-white  text-transparent bg-clip-text'>SOFTWARE SOLUTIONS</span> </p>
                  </div> */}
             </div>
              {/* ==============Logo end============ */}
              {/* ================== Menu start ============= */}

             <div  className="menu bg-opacity-40 "  onClick={toggleMenu}>
                <ul className='flex items-center gap-10 '>
                    <li><a href='#home' className='hover:text-smallTextColor cursor-pointer  text-white font-[600] smooth-transition duration-500'>HOME</a></li>
                    <li><a href='#about-us' className='hover:text-smallTextColor cursor-pointer text-white font-[600] smooth-transition duration-500'>ABOUT US</a></li>
                    <li><a href='#services' className='hover:text-smallTextColor cursor-pointer text-white font-[600] smooth-transition duration-500'>SERVICES</a></li>
                    {/* <li><a href='#blogs' className='hover:text-headingColor cursor-pointer text-smallTextColor font-[600] smooth-transition duration-500'>BLOGS</a></li> */}
                    <li><a href='#contact' className='hover:text-smallTextColor cursor-pointer text-white font-[600] smooth-transition duration-500'>CONTACT</a></li>

                </ul>

             </div>
              {/* ============= Menu end===================== */}

              {/* =========== Menu right =============== */}
              <div className='absolute md:hidden block top-6 right-3'>
          {mobileMenuOpen? 
          (<span ref={buttonRef} onClick={()=>setmobileMenuOpen(false)} className='text-red-900 text-2xl w-6 h-6 mr-2' ><AiOutlineClose size={30}/></span>):(<span ref={buttonRef} onClick={()=>setmobileMenuOpen(true)}  className='text-secondaryColor text-2xl w-6 h-6 mr-2'><AiOutlineMenu size={30}/></span>)}
         </div>
              {/* ============ Menu right end ========== */}
          </div>
       </div>

        {/* mobile menu */}
        <div onClick={toggleMenu} className={`absolute flex flex-col space-y-4 h-screen bg-gray-200 px-6 pt-20 z-30 w-2/3 md:hidden top-0 smooth-transition duration-500   ${mobileMenuOpen? 'left-0 ':'-left-full'}`}>
        <a href='#home' className='hover:text-headingColor cursor-pointer  text-smallTextColor font-[600] smooth-transition duration-500'>HOME</a>
        <a href='#about-us' className='hover:text-headingColor cursor-pointer  text-smallTextColor font-[600] smooth-transition duration-500'>ABOUT US</a>
        <a href='#services' className='hover:text-headingColor cursor-pointer  text-smallTextColor font-[600] smooth-transition duration-500'>SERVICES</a>
        {/* <a href='#blogs' className='hover:text-headingColor cursor-pointer  text-smallTextColor font-[600] smooth-transition duration-500'>BLOGS</a> */}
        <a href='#contact' className='hover:text-headingColor cursor-pointer  text-smallTextColor font-[600] smooth-transition duration-500'>CONTACT</a>
                   
         </div>
       
  </header>
}

export default Header;